import React from "react"
import "../styles/styles.scss"
import ALSPageComponent from "../components/alsPageComponent"
import { Helmet } from "react-helmet"

const LSPage = () => (
 <div>
  <Helmet
    title="Security-Alarm-KMU" defer={false}>
      <html lang="de" />
      <meta name="description" content="ALARM-Projekt -- Security Game 01 -- Der erste Tag" />
      <meta name="keywords" content="Game, Security, ALARM, Awareness, KMU" />
  </Helmet>
    <ALSPageComponent
      ls="1" 
      game="ls1"
      motto="Homeoffice"
      moderation="Als01OModeration"
      handout="Als01OHandout"
      konstruktion="Als01OKonstruktion"
      vorlage="Als01ODruckvorlagen"/>
  </div>   
)

      
export default LSPage
